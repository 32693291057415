@import '../../variables';

.btn_dropdown {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  color: $primary-text-color !important;
  outline: none;

  span:nth-child(2) {
    margin-left: 0.5rem;
    max-width: 8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }
  span:last-child{
    svg{
      width: 0.9rem;
      height: 0.9rem;
    }
    // padding-bottom: 2px;
    // margin-left: 0;
    // overflow:initial;
  }
}

.span_arrow{
  --stroke: #000;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  touch-action: manipulation;
  margin-right: -28px;
}
.subscription_icon {
  --stroke: #a2acba;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  touch-action: manipulation;
}
.btn_icon {
  padding: 0;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  height: calc((1.5rem * 1.5) + (0.5rem * 2) + (2px)) !important;
  width: calc((7.7rem * 1.5) + (0.5rem * 2) + (2px)) !important;
}

.img_fluid {
  // height: calc((1rem * 1) + (0.5rem * 2) + (2px)) !important;
  // width: calc((1rem * 1) + (0.5rem * 2) + (2px)) !important;
  // height: calc((1rem * 1) + (0.4rem * 2) + (1px)) !important;
  width: calc((1rem * 1) + (0.4rem * 2) + (1px)) !important;
  border-radius: 100%;
}

.navbar_expand {
  position: absolute;
  top: calc(100% + 0.3rem + 0.3rem) !important;
  font-size: 0.9rem;
  margin-top: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15) !important;
  z-index: 1000;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  margin-top: 0.125rem;
  font-size: 1rem;
  color: #687281;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6ec;
  border-radius: 0.35rem;
  animation-name: fadeInUp;
  animation-duration: 300ms;
  right: -0.8rem;
}

.dropdown_header {
  text-transform: none;
  letter-spacing: normal;
  color: #a2acba;
  font-size: 0.75rem;
  font-weight: 700;
  align-items: center !important;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  display: flex !important;
  white-space: nowrap;
}

.dropdown_user_img {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
  border-radius: 100%;
}

.dropdown_user_details {
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
  list-style: none;

  .dropdown_user_details_name {
    color: #1f2d41;
    font-weight: 500;
    font-size: 0.9rem;
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown_user_details_email {
    color: #687281;
    font-size: 0.75rem;
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    line-height: 1.2;
  }
}

.dropdown_divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e3e6ec;
}

.dropdown_item {
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1f2d41;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 0.9rem;
}

.dropdown_item_icon {
  color: #a2acba;
  margin-right: 0.5rem;
  line-height: 1;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  font-size: 0.9rem;

  svg {
    height: 0.9em;
    width: 0.9em;
  }
}
