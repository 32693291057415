.root {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-areas: 'before input after';
  grid-template-columns: auto 1fr auto;
  min-height: 2.25rem;
  width: 100%;
}

.input {
  align-items: center;
  display: flex;
  grid-column: before-start / after-end;
  grid-row: input-start / input-end;
}

.input > input {
  padding-left: calc(1.875rem * var(--iconsBefore) + 0.375rem - 1px);
  padding-right: calc(1.875rem * var(--iconsAfter) + 0.375rem - 1px);
}

.before,
.after {
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
  width: 2.25rem;
  z-index: 1;
}

.before:empty,
.after:empty {
  display: none;
}

.before {
  grid-area: before;
}

.after {
  grid-area: after;
}
