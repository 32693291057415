@import '../clickable.scss';

.root {
  color: $primary-text-color;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.375rem 0.125rem 0.125rem;
}

.root:empty {
  display: none;
}

.root_error {
  @extend .root;
  color: #ed254e !important;
  font-family: 'Maison Neue Light';
  font-size: 0.85rem !important;
  margin: 0;
  word-break: break-all;
  white-space: normal;
  cursor: default;
}
