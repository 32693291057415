.container {
  padding: 10% !important;
  background-color: #fff;
}
.rootContainer{
  overflow: hidden;
  position: relative;
}
.rootContainer:after {
  content: "";
  top: -390px;
  right: -214px;
  position: absolute;
  z-index: 10000;
  background: #E7E7E74A;
  width: 700px;
  height: 700px;
  border-radius: 50%;
}
.rootContainer:before {
  content: "";
  bottom: -224px;
  left: -178px;
  position: absolute;
  background: #E7E7E74A;
  border-top-right-radius: 100%;
  width: 800px;
  height: 800px;
  border-radius: 50%;
}
.paddingLess{
  padding-left: 0px !important;
  padding-right: 0px !important;
}


@media (max-width: 1391px) {
  .rootContainer:after {
    top: -365px;
    right: -230px;
    width: 600px;
    height: 600px;
  }
  .rootContainer:before {
    bottom: -220px;
    left: -185px;
    width: 700px;
    height: 700px;
  }
}

@media (max-width: 1024px) {
  .rootContainer:after {
    top: -335px;
    right: -206px;
    width: 500px;
    height: 500px;
  }
  .rootContainer:before {
    bottom: -220px;
    left: -185px;
    width: 600px;
    height: 600px;
  }
}
@media (max-width: 991px) {
  .rootContainer:after {
    top: -204px;
    right: -135px;
    width: 400px;
    height: 400px;
  }
  .rootContainer:before {
    bottom: -45px;
    left: -84px;
    width: 400px;
    height: 400px;
  }
}

