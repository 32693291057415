.root {
    position: fixed;
    // display: grid;
    // grid-row-gap: 1rem;
    z-index: 6;
  
    /* Show toasts on bottom of screen */
    bottom: 0px;
    width: 25rem;
    border: 1px solid;
    border-color: #d1d1d1;
    border-radius: 3px 3px 0px 0px;
  }
  .progress_label {
    background-color: #2B3646;
    color: white;
    padding: 15px 0px 15px 17px;
    border-radius: 3px 3px 0px 0px;
    .toggle_btn {
      border: none;
      float: right;
      padding-top: 0;
      svg {
        stroke: #FFFFFF;
        width: 25px;
        height: 25px;
      }
      span {
        margin-top: -2px;
      }
    }

    .close_btn {
        float: right;
        padding:0;
        padding-right: 1rem;
        span{
          svg{
            stroke: white;
            width: 17px;
            padding-bottom: 4px;
          }
        }
    }
  }
 
  .failed{
    color: #ED254E;
  }
  .failedClass {
    background: #FFF3F6 !important;
  }
  .button {
    padding: 0;
    float: right;
    margin-right: -18px;
    margin-top: -3px;
  }
  .progressBar{
    padding-right: 6px !important;
    svg {
      padding-right: 0px !important;
      width: 15px;
      height: 15px;
    }
  }
  .progress {
    display: flex;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    height: 5px !important;
  }
  .progress_item {
    display: flex;
    margin-right: 4px;
    :first-child {
      padding-right: 10px;
    }
  }
  .progressBar_container {
    max-height: 238px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;

    .progressBar_sub_container{
      background-color: white;
      // border-radius: 2px;
      // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
      color: #212121;
      font-size: 0.8rem;
      padding: 1rem 1rem 0.3rem 0rem;
      display: flex;
      border-bottom: 1px solid;
      border-color: #d1d1d1;
      justify-content: space-between;
  
      .label{
        flex: 1;
        // margin-left: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100px;
        padding-left: 2px;
      
        label{
          padding-left: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 250px;
          white-space: nowrap;
          margin-bottom: 0;
        }
      }
  
      .progress_bar_item{
        // width: 18rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 26px;
        label{
          flex: 1;
          // margin-left: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100px;
          padding-left: 2px;
        }
        .close{
          background-color: transparent;
          height: 25px;
          width: 25px;
          margin-top: -3px;
        }
      }
    }
  }
  .not_progressBar_container {
    display: none;
  }
  
  .datasetLabel_container {
    max-height: 238px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;

    .dataSet_sub_container{
      background-color: white;
      // border-radius: 2px;
      // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
      color: #212121;
      font-size: 0.8rem;
      border-bottom: 1px solid;
      border-color: #d1d1d1;
      justify-content: space-between;
      
      // label{
      //   padding-left: 10px;
      //   text-overflow: ellipsis;
      //   overflow: hidden;
      //   max-width: 250px;
      //   white-space: nowrap;
      //   margin-bottom: 0;
      // }

      .dataset_label {
        background-color: #EAEDF3;
        color: #000000;
        padding: 15px 5px 15px 17px;
        border-radius: 3px 3px 0px 0px;
        label {
          max-width: 100px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          vertical-align: -webkit-baseline-middle;
        }
        .dataset_buttons {
          float: right;
          margin-right: 5px;

          label {
            text-overflow: unset;
            max-width: unset;
            vertical-align: bottom;
          }
  
          .cog_btn {
            border: none;
          }
          .toggle_btn {
            border: 0;
            padding-top: 0;
  
            svg {
              stroke: #000000;
              width: 19px;
              height: 19px;
            }
            span {
              margin-top: -2px;
              vertical-align: text-top;
            }
          }
        }
  
        .close_btn {
            float: right;
            padding:0;
            padding-right: 1rem;
            span{
              svg{
                stroke: #000000;
                width: 17px;
                padding-bottom: 4px;
              }
            }
        }

        .dataset_name {
          background-color: white;
          // border-radius: 2px;
          // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
          color: #212121;
          font-size: 0.8rem;
          padding: 1rem 1rem 0.3rem 0rem;
          border-bottom: 1px solid;
          border-color: #d1d1d1;
          display: flex;
          justify-content: space-between;
          .dataset_label{
            flex: 1;
            // margin-left: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100px;
            padding-left: 2px;
          }
        }
        label {
          margin-bottom: 0px;
        }
  
        label:nth-child(2) {
          // margin-left: 9rem;
          // margin-right: 0.5rem;
        }
      }
    }
  }
  .not_datasetLabel_container {
    display: none;
  }
  

  
  /* On desktop, display on right */
  @media (min-width: 1024px) {
    .root {
      right: 1.5rem;
      min-width: auto;
    }
  }
  