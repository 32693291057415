button{
  position: relative;
  display: inline-block;
}
button:focus{
  outline: 0;
}


.root{
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 4px;
  padding: 6px 12px;
  position: absolute;
  // max-width: 200px;
  // min-width: 200px;
  z-index: 1;
  bottom: 150%;
  right: -70px;
  margin-left: -60px;
  box-shadow: 1px 1px 1px 1px #ddd;

  font-size: .84rem;
  font-family: 'Maison Neue Light' !important;
  font-weight: 700;
  line-height: 1rem;

}

.root::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 55%;
  margin-left: -6px;
  border-width: 7px;
  border-style: solid;
  border-color: #d9d5d5 transparent transparent transparent;
}
  .root::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 55%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

button:hover .root {
  visibility: visible;
}