@import '~bootstrap/scss/bootstrap.scss';
@font-face {
  font-family: 'Maison Neue Light';
  src: url('/fonts/MaisonNeueLight.woff2')
      format('woff2'),
    url('/fonts/MaisonNeueLight.svg')
      format('svg');
}
@font-face {
  font-family: 'Maison Neue';
  src:  url('/fonts/MaisonNeueBold.woff2')
      format('woff2'),
    url('/fonts/MaisonNeueBold.svg')
      format('svg');
}
@font-face {
  font-family: 'Maison Neue Red Bull Demi';
  src:  url('/fonts/MaisonNeueRedBullDemi.woff2')
      format('woff2'),
    url('/fonts/MaisonNeueRedBullDemi.svg')
      format('svg');
}
body {
  margin: 0;
  font-family: 'Maison Neue Red Bull Demi';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b3646;
}

html[data-scroll-lock='true'],
html[data-scroll-lock='true'] body {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn:focus,
.btn.focus,
.form-control:focus {
  box-shadow: none;
}

.form-control:focus {
  border-color: none !important;
}
