.root {
    display: none;
  }
  
  .root_open {
    display: block;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.19);
    :global{
      .modal-header{
        background-color: transparent;
        border: none;
        h5{
          text-align: center;
          display: block;
          width: 100%;
        }
      }
    }
  }
  .modal_content {
    border: none;
    padding-top: 15px;
    min-height: 130px;
    border-radius: 16px;
    max-width: 35rem;
    min-width: max-content;
  }

  .downgrade_content {
    border: none;
    min-width: 539px;
    min-height: 337px;
    border-radius: 5px;
    left: -6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }