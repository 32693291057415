@import '../Field/field.module.scss';

.input {
  @extend .input;
}
.input_error {
  border: 1px solid rgb(219, 10, 10) !important;
}

.input:disabled {
  color: #2b3646;
}
.button {
  border: 0;
  background: 0;
  margin-left: -50px;
  z-index: 999;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}