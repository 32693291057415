@import '../clickable.scss';
.root {
  color: $primary-text-color;
  display: grid;
  align-content: start;
}

.label {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.375rem 0.125rem;
  min-height: 1.75rem;
}

.input {
  background: white;
  border: 1px solid $secondary-text-color;
  border-radius: 2px;
  color: $primary-text-color;
  display: inline-flex;
  flex: 0 0 100%;
  font-size: 1rem;
  min-height: 2.25rem;
  margin: 0;
  max-width: 100%;
  padding: calc(0.375rem - 1px) calc(0.625rem - 1px);
  width: 100%;
  -webkit-appearance: none;
}

.input:focus {
  border-color: $secondary-text-color;
  box-shadow: 0 0 0 2px $secondary-text-color,
    0 0 0.5rem 2px $secondary-text-color;
  outline: none;
}

.requiredSymbol {
  background-color: $primary-text-color;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}
