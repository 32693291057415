.root{
    display:block;
    position: absolute;
    z-index: 9999;
    font-size: .9rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #777777;
    text-decoration: none;
    border-radius: 2px;
    padding: 4px 8px;

}


   .root::before {
    content: '';
    display: block;  
    position: absolute;
    left: 20px;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: black;
  }
  .root::after {
    content: '';
    display: block;  
    position: absolute;
    left: 21px;
    top: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: white;
}