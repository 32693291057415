$background-color: '#f6f7fa';

$negative-color: '#ed254e';
$warning-color: '#f9dc5c';
$positive-color: '#91d56c';

$primary-color: '#196cf3'; //blue
$primary-text-color: '#2b3646';
$secondary-text-color: '#8d959d';

.root {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  pointer-events: auto;
  text-align: center;
  white-space: nowrap;
}
