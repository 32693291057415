@import '../../variables';

.nav_item::after {
  position: absolute;
  content: ' ';
  height: calc(100% - 0.8rem);
  width: 1px;
  background-color: #ced2dc;
  right: -0.8rem;
  top: 0.3rem;
}

.btn_dropdown {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  color: rgba(31, 45, 65, 0.5) !important;

  span {
    position: absolute;
    background-color: #f63764;
    color: #fff;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 0.3rem;
    right: 0.3rem;
    padding: 1px 2px;
  }
}

.btn_icon {
  padding: 0;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  height: calc((1rem * 1.5) + (0.5rem * 2) + (2px)) !important;
  width: calc((1rem * 1.5) + (0.5rem * 2) + (2px)) !important;
  white-space: nowrap;

  svg {
    height: 1.4rem;
    width: 1.4rem;
    overflow: hidden;
    fill: #0061f2;
  }
}

.dropdown-item {
  font-weight: 400;
  color: #1f2d41;
  text-align: inherit;
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0.125rem 0 0;
  margin-top: 0.125rem;
  font-size: 1rem;
  color: #687281;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6ec;
  border-radius: 0.35rem;
}

.navbar_expand {
  @extend .dropdown-menu;
  width: auto;
  min-width: 15rem;
  right: 0;
  left: auto;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 19rem;
  overflow-x: hidden;
  overflow-y: hidden;
  top: calc(100% + 0.5rem + 0.5rem) !important;
  font-size: 0.9rem;
  margin-top: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15) !important;
}

.dropdown_header {
  background-color: #0061f2;
  color: #fff;
  padding: 0.5rem 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap;
}

.dropdown_item {
  clear: both;
  @extend .dropdown-item;
  background-color: transparent;
  border: 0;
  width: 100%;
  padding: 0.25rem 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e3e6ec;
  display: flex;
  align-items: center;

  .dropdown_notifications_item_icon {
    @extend .dropdown-item;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    margin-right: 1rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      text-align: center;
      font-size: 0.85rem;
      color: #fff;
      width: 1rem;
      height: 0.85rem;
    }
  }

  .dropdown_notifications_item_content {
    @extend .dropdown-item;
    font-size: 0.9rem;
  }

  .dropdown_notifications_item_content_details {
    @extend .dropdown-item;
    color: #a2acba;
    font-size: 0.7rem;
  }

  .dropdown_notifications_item_content_text {
    @extend .dropdown-item;
    max-width: 13rem;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bg_success {
  background-color: #00ac69 !important;
}

.dropdown_item_footer {
  @extend .dropdown_item;
  justify-content: center;
  font-size: 0.8rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #a2acba;
  cursor: pointer;
}
