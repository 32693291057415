.root {
  background-color: black;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 56px;
  transition-duration: 192ms;
  transition-property: opacity, visibility;
  transition-timing-function: linear;
  visibility: hidden;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  border: none;
}

/* state: active */

.root_active {
  @extend .root;
  opacity: 0.5;
  transition-duration: 224ms;
  visibility: visible;
}
.root_active_fullscreen {
  @extend .root_active;
  opacity: 0;
  z-index: 2;
}


@media (max-width: 991px) {
  .root {
    top: 59px;
  }
}

