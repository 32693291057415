.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #D7D8DD;
}
::-webkit-scrollbar-track {
  background: #EAEDF3; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #D7D8DD; 
}

::-webkit-scrollbar-thumb:hover {
  background: #D7D8DD; 
}

.mask_class {
  // display: block;
  // z-index: 2000;
  color: white !important;
  background-color:inherit !important;
  box-shadow: none !important;
  border-top:none !important;
  width: 500px !important;
  position: fixed !important;
  top: 3px !important;
  max-width: unset !important;
  overflow: hidden;
  overflow-y: hidden;
}
.mask_class_btn {
  @extend .mask_class;
  .introjs-tooltipbuttons {
    float:right;
  }
}

.highlight_class {
  background-color:rgba(240, 238, 238, 0.73) !important;
  top:unset !important;
  left: unset !important;
  width: unset !important;
  height: unset !important;
}
.introjs-arrow.top, .introjs-arrow, .introjs-tooltip-header {
  visibility: hidden !important;
}

.introjs-tooltipbuttons {
  border-top:none !important;
  border-radius: 3px !important;
  .introjs-nextbutton {
    background-color:#196CF3 !important;
    color: white !important;
    float: left;
    margin-left: 1rem;
  }
}


.step_fun {
  display: flex;
  flex-direction: column;
  span{
      align-self: flex-end;
  }
  
}
.R{
  text-align: end;
}
.L{
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
