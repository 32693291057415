.root {
  background-color: #fff;
  color: #1f2d41;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  font-size: 0.9rem;
  padding-top: 4rem;
  border-right: 1px solid #eaeff1;
  &::before{
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background: #EAEFF1;
    top: 0;
    left: 0;
  }
  // box-shadow: 0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.sidenav_menu {
  font-size: 15px;
  color: #1f2d41;
  flex-grow: 1;
  padding-bottom: 10rem;
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;
  scrollbar-width: thin;
  &:hover {
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
  }
}

.sidenav_menu_heading {
  padding: 1.75rem 1rem 0.75rem;
  font-size: 16px;
font-weight: 700;
// text-transform: uppercase;
letter-spacing: 0.05em;
color: #151A2E;
list-style: none;
}

.nav_accordion {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: column;
  flex-wrap: nowrap;
}

.nav_link {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  line-height: normal;
  position: relative;
  color: #8a939c;
  font-weight: 500;

  &:hover {
    color: #196cf3;

    .nav_link_icon {
      color: #196cf3;
    }
  }

  &.active {
    background-color: #e6eefd;
    color: #196cf3;
    font-weight: 500;

    .nav_link_icon {
      color: #196cf3;
      svg {
        path {
          fill: #196cf3;
        }
        g {
          path {
            fill: none;
            stroke: #196cf3;
          }
        }
      }
    }

    &::before {
      position: absolute;
      content: ' ';
      width: 0.2rem;
      height: 100%;
      background-color: #196cf3;
      left: 0;
    }
  }

  &:hover {
    text-decoration: none;
  }

  .nav_link_icon {
    font-size: 0.9rem;
    padding-right: 0.5rem;
    display: inline-flex;
    color: #a2acba;
    margin-right: 0.5rem;
    margin-top: -4px;

    svg {
      width: .9rem;
      height: .9rem;
      path {
        fill: #a2acba;
      }
      g {
        path{
          fill: none;
          stroke: #a2acba;;
        }
        
      }
    }
  }
}

.nav_link_disabled {
  @extend .nav_link;
  // pointer-events: none;
  color: #cfd4da;
}

.nav_hint_link {
  @extend .nav_link;
  margin: 0.3rem 1rem;
  padding: 0.75rem;
  background-color: #f6f6fa;
  border-radius: 4px;
  border: 1px solid #e0e3ef;
  box-shadow: 0 0.06rem 0.1rem 0 rgba(31, 45, 65, 0.15) !important;
  font-family: 'Maison Neue Light';
  font-weight: bold;

  div {
    align-self: center;
    height: 1.2rem;

    label {
      width: 1.2rem;
      height: 1.2rem;
      background-color: #196cf3;
      color: #fff;
      border-radius: 50%;
      padding: 0rem 0.32rem;
    }
  }

  span {
    margin-left: 0.5rem;
    color: #2e3748;
    line-height: 1.2rem;
    font-size: 0.9rem;
  }
}

.logout_item {
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1f2d41;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 0.9rem;
}

.logout_item_icon {
  color: #a2acba;
  margin-right: 0.5rem;
  line-height: 1;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  font-size: 0.9rem;

  svg {
    height: 0.9em;
    width: 0.9em;
  }
}

.logout_mobile {
  display: none;
}

@media (max-width: 1023px) {
  .logout_mobile {
    display: block;
    margin-top: 1rem;
  }
  .root {
    height: 100vh;
  }
}

@media (max-width: 991px) {
  .root {
    padding-top: 0;
  }
}

@supports (-moz-appearance: none) {
  @media (min-width: 768px) {
  .root {
    height: 100vh;
    // padding-bottom: 3.5rem;  
  }
 }
}
