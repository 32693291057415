  .root {
    display: block;
    
        .emptyData_temp {
          font-size: 14px;
            position: absolute;
            top: 14rem;
            left: 13rem;
        }
        
        .header_notice {
          display: flex;
          margin-bottom: 0.5rem;
          padding-top: 2rem;

          .alert_icon {
            height: 14px;
            width: 14px;
          }

          .downgrade_notice {
            font-family: Maison Neue;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            padding-left: 0.4rem;
            
          }
        }

        .btn_pos {
            position: absolute;
            bottom: 11px;
            right: 7px;
            
          .btn{
            min-width: 100px;
            min-height: 40px;
            font-size: 14px;
            margin-left: 8px;
            margin-right: 8px;
            box-shadow: 0 0.05rem 0.1rem 0 #dce3ec;
            border-radius: 0.25rem;
            border: solid 1px #196cf3;

            &.btnsecondary{
              background-color: #fff;
              color: #2B3646;
              border: 1px solid #EAEDF3;
            }
            &.btn_primary{
              background-color: #196CF3;
              color: #fff;
            }
          }
        }

        .downgrade_table {
          width: 100%;

          .loading_temp {
            font-size: 14px;
            position: absolute;
            top: 12rem;
            left: 15rem;
          }

          .downgrade_table_header {
            width: 100%;
            background-color: #F9F9F9;
            border-top-style: solid;
            border-bottom-style: solid;
            border-width: 1px;
            border-color: #EAEDF3;

            .downgrade_header_content {
              font-family: Maison Neue;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 15px;
              letter-spacing: 0px;
              text-align: left;
              color: #8D959D;
              display: flex;
              justify-content: space-around;

              th {
                padding: 0.8rem;
                min-width: 31%;
              }
            }
          }
          .downgrade_table_body {
            width: 100%;
            height: 162px;
            overflow-y: auto;
            display: block;

            .row_content {
              border-bottom-style: solid;
              border-width: 1px;
              border-color: #EAEDF3;
              display: flex;
              justify-content: space-around;
              
              .team_content {
                font-family: Maison Neue;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
                padding: 0.8rem;
                min-width: 30%;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 125px;
                overflow: hidden;
              }

              .name_content {
                font-family: Maison Neue;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
                padding: 0.8rem;
                min-width: 30%;
              }
            }
          }
        }
    }