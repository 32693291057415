.root_indicator {
  align-content: center;
  align-items: center;
  display: grid;
  gap: 3rem;
  height: calc(100% - 6rem);
  justify-content: center;
  justify-items: center;
  margin: 3rem 0;
  width: 100%;
}

.global {
  @extend .root_indicator;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.message {
  font-size: 1rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
}

.indicator {
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: pulse;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}


/* Loader 5 */
.loader_5 {
	height: 40px;
	width: 40px;
	-webkit-animation: loader_5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_5-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader_5::before {
	content: "";
	display: block;
	position: absolute;
  // top: 0; 
  left: 0;
	bottom: 0; right: auto;
	margin: auto;
	width: 18px;
	height: 18px;
	background: #3E44F4;
	border-radius: 50%;
	-webkit-animation: loader_5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(32px, -32px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-2 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(32px, -32px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_5::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: auto;
  // bottom: 0; 
  right: 0;
	margin: auto;
	width: 18px;
	height: 18px;
  background: #3E44F4;
	border-radius: 50%;
	-webkit-animation: loader_5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-3 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-32px, 32px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-32px, 32px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_5 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 40px;
	width: 40px;
}
.loader_5 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
  bottom: auto; 
  // right: 0;
	margin: auto;
	width: 18px;
	height: 18px;
	background: #00DC1F;
	border-radius: 50%;
	-webkit-animation: loader_5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-4 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(32px, 32px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(32px, 32px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_5 span::after {
	content: "";
	display: block;
	position: absolute;
	// top: auto; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 18px;
	height: 18px;
	background: #00DC1F;
	border-radius: 50%;
	-webkit-animation: loader_5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-5 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-32px, -32px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-5 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-32px, -32px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}


.center {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}