.root {
  background-color: #eff3f9;
  color: #000;
  position: relative;
  z-index: 1;
}

.root_masked {
  @extend .root;
}

.page {
  min-height: 100vh;
  z-index: -1;
  position: relative;
  width: 100%;
}

.page_masked {
  @extend .page;
  position: fixed;
}
.preload{
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(239,243,249, 0.5);
}
