:root {
    display: block;

    .modal_footer {
        // padding-top: 3.2rem;
        padding-bottom: 0rem;
        // position: fixed;
        top: 370px;
        right: 865px;
        border: none;
        display: flex;
        justify-content: center;
        .btn{
          min-width: 120px;
          min-height: 40px;
          font-size: 14px;
          margin-left: 8px;
          margin-right: 8px;
          border-radius: 8px;
          border: solid 1px #196cf3;
          box-shadow: 0 0.05rem 0.1rem 0 #dce3ec;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          &.btn_secondary{
            background-color: #fff;
            color: #2B3646;
            border: 1px solid #EAEDF3;
          }
          &.btn_primary{
            background-color: #196CF3;
            color: #fff;
          }
          &:disabled {
            opacity: 0.8;
          }
        }
      }

    .modal_body{
        .iconContainerDailog{
          display: flex;
          justify-content: center;
          margin: 10px 0;
        }
        h5{
          text-align: center;
          display: block;
          width: 100%;
          font-size: 16px;
          padding-top: 8px;
          color: #000;
          line-break: anywhere;
          padding: 0px 0.8rem;
        }
        p{
          text-align: center;
          font-size: 12px;
          color: #555555;
          padding-top: 8px;
        }
        .border_bottom{
          display: block;
          height: 8px;
          width: calc(100% - 80px);
          border-bottom: 1px dashed #BBBBBB;
          margin: 0 40px;
          margin-bottom: 10px;
        }
    }

    .modal_content {
        border: none;
        padding-top: 15px;
        min-height: 130px;
        border-radius: 5px;
    }
}