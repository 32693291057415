
.circle_background,
.circle_progress {
  fill: none;
}

.circle_background {
  stroke: #EAEDF3;
}

.circle_progress {
  stroke: #196CF3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle_text {
  font-size: 3em;
  font-weight: bold;
  fill: rgb(5, 15, 165);
}