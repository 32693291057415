.root {
  --stroke: #000;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  touch-action: manipulation;
}

.icon {
  fill: var(--fill, none);
  stroke: var(--stroke);
}
